import { Injectable } from '@angular/core';
import { Drivers, Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  /**  
*  Init database
*/
  async init() {
    const storage = new Storage({
      name: '__gastro_master',
      driverOrder: [Drivers.SecureStorage, Drivers.IndexedDB, Drivers.LocalStorage]
    });
    storage.create();

    this._storage = storage;
  }

  /**
 * Returns nothing
 *
 * @param {string} key The key
 * @param {any} value The data which need to store 
 */
  public async set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  /**
* Returns storage value
*
* @param {string} key The key 
* @return {Storage} return value
*/
  public async get(key: string) {
    return await this._storage?.get(key);
  }

}
