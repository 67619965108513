import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private http: HTTP,
    public httpClient: HttpClient,
    private platform: Platform
  ) { }

  // /
  //  * Returns http promiss request
  //  *
  //  * @param {string} url The api path
  //  * @param {any} data The data which posting
  //  * @return {HttpClient or http} http request.
  //  */
  async get(url: string, data: any): Promise<any> {
    if ((this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb')) {
      this.http.setDataSerializer("json");
      return await this.http.get(environment.API_URL + url, data, {}).then(response => {
        return JSON.parse(response.data);
      }).catch(error => {
        return {};
      });
    } else {
      return await this.httpClient.get(environment.API_URL + url, data).toPromise();
    }
  }

  // /
  //  * Returns http promiss request
  //  *
  //  * @param {string} url The api path
  //  * @param {any} data The data which posting
  //  * @return {HttpClient or http} http request.
  //  */
  async post(url: string, data: any): Promise<any> {
    if ((this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb')) {
      this.http.setDataSerializer("json");
      return await this.http.post(environment.API_URL + url, data, {}).then(response => {
        return JSON.parse(response.data);
      }).catch(error => {
        return {};
      });
    } else {
      return await this.httpClient.post(environment.API_URL + url, data).toPromise();
    }
  }

}