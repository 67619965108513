import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppServiceService } from '../api/app-service.service';
import { StorageService } from '../core/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private storage: StorageService, private router: Router, private appService: AppServiceService) { }

  canActivate(route: ActivatedRouteSnapshot): any {

     // console.log(route);
    //set side menu status 
      this.appService.isSideMenu = route.data.sideMenu;
      this.appService.isFooterMenu = route.data.footerMenu; 
      this.appService.appObject.errors = [];  
 

    //no need to login can access
    return true;
  }

}
